<template>
  <section id="resume-totales" class="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 font-bold lg:grid-cols-5 gap-4">

    <div class="box bg-gray-400 p-2 rounded-md flex flex-col  w-full ">
      <h2 class="text-lg font-medium w-full text-center truncate text-black dark:text-white">
        {{$h.formatCurrency(totals.total_facturado) || 0}}
      </h2>
      <div class="capitalize text-center text-gray-700 dark:text-white">
        Total Facturado
      </div>
    </div>
    <div class="box bg-gray-400 p-2 rounded-md flex flex-col items-center h-full justify-center">
      <h2 class="text-lg font-medium text-center truncate text-black dark:text-white">
        {{$h.formatCurrency(totals.total_consum) || 0}}
      </h2>
      <div class="capitalize text-center text-sm text-gray-700 dark:text-white">
        Total Consultas
      </div>
    </div>
    <div class="box bg-gray-400 p-2 rounded-md flex flex-col items-center justify-center">
      <h2 class="text-lg font-medium text-center truncate text-black dark:text-white">
        {{$h.formatCurrency(totals.total_proced) || 0}}
      </h2>
      <div class="capitalize text-center text-gray-700 dark:text-white">
        Total Procedimientos
      </div>
    </div>
    <div class="box bg-gray-400 p-2 rounded-md flex flex-col items-center justify-center">
      <h2 class="text-lg font-medium text-center text-black dark:text-white">
        {{$h.formatCurrency(totals.total_mx) || 0}}
      </h2>
      <div class="capitalize text-center text-gray-700 dark:text-white">
        Total Medicamentos
      </div>
    </div>
    <div class="box bg-gray-400 p-2 rounded-md flex flex-col items-center justify-center">
      <h2 class="text-lg font-medium text-center text-black dark:text-white">
        {{$h.formatCurrency(totals.otros_servicios) || 0}}
      </h2>
      <div class="capitalize text-center text-gray-700 dark:text-white">
        Total Otros Servicios
      </div>
    </div>

  </section>
</template>

<script>
import { ref, watch } from 'vue'
import InformesRipsService from '../services'

export default {
  name: 'resumenTotales',
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const _informesRipsService = new InformesRipsService()
    const totals = ref({
      total_facturado: 0,
      total_consum: 0,
      total_proced: 0,
      total_mx: 0,
      otros_servicios: 0
    })

    watch(props.filters, (filter) => {
      if (filter.year !== '') {
        fetchTotales(filter)
      }
    })

    const fetchTotales = (filters) => {
      totals.value = {}
      _informesRipsService.apiResumeTotales(filters).then(({ data, status }) => {
        if (status === 200) {
          totals.value = data[0]
        }
      }).catch((err) => {
        console.error(err.message)
      })
    }

    return {
      totals,
      _informesRipsService
    }
  }
}
</script>

<style>
 @media print {
   #resume-totales{
     grid-template-columns: repeat(2, minmax(0, 1fr));
     display: grid;
   }
 }
 .bg-indicador{
   background: #bbbbbb94;
 }

</style>
